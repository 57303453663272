export default {
  white: '#fff',
  black: '#000',
  gray: '#eee',
  red: '#ff0000',
  transparent: 'transparent',
  primary: '#506D8A',
  secondary: '#FBF7F2',
  lightBrown: '#757575',
  lightGray: '#959595',
  bismark: '#516E85',
  blueBayoux: '#415E74',
  blueDianne: '#1F4B4C',
  greenGable: '#153C3C',
  sanJuan: '#2B4C66',
  alto: '#D7D7D7',
  pampas: '#F1EFEC',
  moonMist: '#ECE3E3D7',
  mercury: '#E7E7E7',
};
