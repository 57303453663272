/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/camelcase */

// libraries
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

// custom components
import ServicesDropdown from '../ServicesDropdown';
import Icon from 'components/Icon';
import BurgerMenu from './BurgerMenu';

// styled componentns
import { Flex, Frame, PrimaryButton, SecondaryButton, Link, CentredFlex } from 'shared/styledComponents';
import { Title } from 'shared/typography';

// styles
import { ButtonBaseStyles, TitleStyles } from 'shared/styles';
import devices from 'theme/devices';

// assets
import robynLogo from 'assets/images/robyn-logo.png';
import logoWhite from 'assets/images/logo-white.png';

// constants
import { UserRoles } from 'constants/user';

const HeaderWrapper = styled(Flex)<any>(
  ({ theme: { colors }, istransparent }) => `
  width: 100%;
  padding: 60px 0;
  background-color: ${istransparent ? colors.transparent : colors.secondary};
  ${
    istransparent &&
    `
    position: absolute;
    left: 0;
    top: 0;
  `
  }
  `,
);

const LogoImage = styled.img`
  width: 140px;
  @media ${devices.mobile} {
    width: 100px;
    height: 45px;
  }
`;

const Navigation = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  max-width: 950px;
  @media ${devices.mobile} {
    width: fit-content;
    margin-left: 0;
  }
`;

const NavigationBlock = styled(Flex)<any>(
  ({ isMenuOpened }) => `
    width: 100%;
    max-width: 430px;
    justify-content: space-between;
    align-items: center;

    a,
    button {
      font-size: 15px;
      text-align: center
    }
    @media ${devices.mobile} {
      display: ${isMenuOpened ? 'flex' : 'none'};
      flex-direction: column;
      align-items: start;
      min-height: 200px;
      padding-top: 50px;
    }
  `,
);

const LogInOrSignUpButton = styled(Title)<any>(
  ({ theme: { colors }, isMenuOpened }) => `
    ${ButtonBaseStyles};
    background-color: ${colors.transparent};
    font-size: 15px;
    @media ${devices.mobile} {
      display: ${isMenuOpened ? 'block' : 'none'};
      margin-top: ${isMenuOpened ? '25px' : '0'};
    }
  `,
);

const LogOutButton = styled(SecondaryButton)<any>`
  ${TitleStyles};
  letter-spacing: 0;
  text-transform: capitalize;
  cursor: pointer;
`;

const BookButton = styled(PrimaryButton)`
  margin-left: 30px;
  @media ${devices.mobile} {
    margin-left: 10px;
    padding: 15px 16px;
    font-size: 10px;
    line-height: 13px;
  }
`;

const StyledSeparator = styled.span<any>`
  ${TitleStyles};
  user-select: none;
`;

const ListPracticeButton = styled(PrimaryButton)(
  ({ theme: { colors }, isMenuOpened, istransparent }) => `
    padding: 15px 16px;
    margin-left: 10px;
    background-color: ${colors.transparent};
    border: 2px solid ${istransparent ? colors.white : colors.primary};
    color:${istransparent ? colors.white : colors.primary};
    @media ${devices.mobile} {
      display: ${isMenuOpened ? 'inline-block' : 'none'};
      margin-left: ${isMenuOpened ? '0px' : '10px'};
    }
  `,
);

const StyledIcon = styled(Icon)`
  display: none;
  margin-left: 20px;
  cursor: pointer;
  @media ${devices.mobile} {
    display: inline-block;
  }
`;

const Wrapper = styled(CentredFlex)`
  @media ${devices.mobile} {
    justify-content: flex-end;
  }
`;

const HeaderBlock = styled(Flex)`
  justify-content: space-between;
`;

const SignBlock = styled(Flex)<any>(
  ({ isMenuOpened, theme: { colors } }) => `
  @media ${devices.mobile} {
    ${!isMenuOpened ? 'display: none;' : ''};
    padding: 25px 0 16px;
    border-bottom: 1px solid ${colors.mercury};
  }
`,
);

interface HeaderProps {
  services: any;
  isUserLoggedIn: boolean;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  onModalOpen: (title: string, data?: object | undefined) => () => void;
  onLogOut: VoidFunction;
  onMenuOpen: VoidFunction;
  onMenuClose: VoidFunction;
  isMenuOpened: boolean;
  istransparent?: boolean;
}

const Header = ({
  services,
  isUserLoggedIn,
  userRole,
  userFirstName,
  userLastName,
  onModalOpen,
  onLogOut,
  onMenuOpen,
  onMenuClose,
  isMenuOpened,
  istransparent,
}: HeaderProps): JSX.Element => (
  <HeaderWrapper as="header" istransparent={istransparent ? 1 : 0}>
    <Frame>
      <HeaderBlock>
        {!isMenuOpened ? (
          <>
            <ReactRouterLink to="/">
              <LogoImage data-src={istransparent ? logoWhite : robynLogo} alt="Robyn logo" className="lazyload" />
            </ReactRouterLink>
            <Navigation as="nav">
              <NavigationBlock>
                <Link
                  href="https://www.parentbirth.com/"
                  target="_blank"
                  size="small"
                  iswhitecolor={istransparent ? 1 : 0}
                >
                  Virtual Doula
                </Link>
                <Link as={ReactRouterLink} to="/journeys" size="small" iswhitecolor={istransparent ? 1 : 0}>
                  Journeys
                </Link>
                <Link as={ReactRouterLink} to="/learn" size="small" iswhiteColor={istransparent ? 1 : 0}>
                  Articles
                </Link>
                {/* <Link as={ReactRouterLink} to="/classes" size="small" iswhitecolor={istransparent ? 1 : 0}>
                  Class
                </Link> */}
                <ServicesDropdown services={services} iswhitecolor={istransparent} />
                <Link as={ReactRouterLink} to="/for-providers" size="small" iswhitecolor={istransparent ? 1 : 0}>
                  For Providers
                </Link>
              </NavigationBlock>
              <Wrapper>
                <SignBlock isMenuOpened={isMenuOpened}>
                  {isUserLoggedIn ? (
                    <>
                      {userRole === UserRoles.PROVIDER_APPLICANT && (
                        <Title size="small">
                          {userFirstName} {userLastName}
                        </Title>
                      )}
                      {userRole === UserRoles.PROVIDER_WITHOUT_SUBSCRIPTION && (
                        <Link as={ReactRouterLink} to={'/provider/dashboard/settings'} size="small" iswhitecolor={true}>
                          My Dashboard
                        </Link>
                      )}
                      {(userRole === UserRoles.PROVIDER_BASIC || userRole === UserRoles.PROVIDER_PRO) && (
                        <Link
                          as={ReactRouterLink}
                          to={'/provider/dashboard/edit-profile'}
                          size="small"
                          iswhitecolor={istransparent ? 1 : 0}
                        >
                          My Dashboard
                        </Link>
                      )}
                      {userRole === UserRoles.CLIENT && (
                        <Link
                          as={ReactRouterLink}
                          to={'/client/dashboard/personal-details'}
                          size="small"
                          iswhitecolor={istransparent ? 1 : 0}
                        >
                          My profile
                        </Link>
                      )}
                      <StyledSeparator size="small" iswhitecolor={istransparent ? 1 : 0}>
                        &nbsp;/&nbsp;
                      </StyledSeparator>
                      <LogOutButton size="small" onClick={onLogOut} iswhitecolor={istransparent ? 1 : 0}>
                        Log Out
                      </LogOutButton>
                    </>
                  ) : (
                    <LogInOrSignUpButton
                      isMenuOpened={isMenuOpened}
                      as="button"
                      size="small"
                      onClick={onModalOpen('logInOrCreateAccount')}
                      iswhitecolor={istransparent ? 1 : 0}
                    >
                      Log In / Sign Up
                    </LogInOrSignUpButton>
                  )}
                </SignBlock>

                <BookButton
                  as={ReactRouterLink}
                  to="/find-providers"
                  size="small"
                  onClick={() =>
                    // @ts-ignore
                    gtag('event', 'Book Now Click', {
                      event_category: 'Header',
                      event_label: 'Button Click',
                    })
                  }
                >
                  Book Now
                </BookButton>
                {!isUserLoggedIn && (
                  <ListPracticeButton
                    as={HashLink}
                    to="#listYourPractice"
                    size="small"
                    smooth
                    istransparent={istransparent ? 1 : 0}
                    onClick={() =>
                      // @ts-ignore
                      gtag('event', 'List Practice Click', {
                        event_category: 'Header',
                        event_label: 'Button Click',
                      })
                    }
                  >
                    List Your Practice
                  </ListPracticeButton>
                )}

                <StyledIcon type={istransparent ? 'burgerMenuWhite' : 'burgerMenu'} onClick={onMenuOpen} />
              </Wrapper>
            </Navigation>
          </>
        ) : (
          <BurgerMenu
            isMenuOpened={isMenuOpened}
            onMenuClose={onMenuClose}
            isUserLoggedIn={isUserLoggedIn}
            userRole={userRole}
            userFirstName={userFirstName}
            userLastName={userLastName}
            onLogOut={onLogOut}
            onModalOpen={onModalOpen}
            services={services}
            istransparent={istransparent}
          />
        )}
      </HeaderBlock>
    </Frame>
  </HeaderWrapper>
);
export default Header;
